<template>
    <div>
        <!-- home -->
        <v-img src="../assets/banner.jpg" max-height="500" gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.3)">
            <v-row class="fill-height" justify="center" align="center">
                <div class="text-center white--text font-weight-black body-2 text-md-body-1 text-lg-h6 header">
                    <h1 class="mb-10">www.ATSFARES.com</h1>
                    <h1>EMPOWERING TRAVEL AGENTS</h1>
                </div>
            </v-row>
        </v-img>

        <!-- New version alert -->
        <v-alert
        border="top"
        colored-border
        icon="mdi-alert"
        type="warning"
        elevation="2"
        class="my-5 mx-15"
        >
            Dear user, This is a newer and upgraded version of ATS Fares. You'll be able to find all the old features as well with the same experience.
            <br>
            If you found anything inconvient or faced any problem; please feel free to reach us at <a class="blue--text" href="mailto:support@atsfares.com">support@atsfares.com</a>.
            <br>
            It will help us solve your problem faster and with more accuracy if youe provide us with problem explanation and screenshots.
        </v-alert>

        <!-- airlines -->
        <v-sheet color="grey lighten-4 pb-7">
            <v-slide-group
            v-model="model"
            class="pa-4"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            show-arrows
            >
                <v-slide-item v-for="n in 9" :key="n">
                    <v-img class="ma-4 airline-img" width="200" :src="require('../assets/airlines/'+n+'.jpg')"></v-img>
                </v-slide-item>
            </v-slide-group>
            <div v-if="user">
                <v-hover  v-if="(user.role !== 'subAgent' && user.role !== 'ats_subAgent') || user.permissions.includes('can-see-contracts')" v-slot="{ hover }">
                    <v-btn x-large elevation="5" :color="hover ? 'blueDark' : 'orange'" class="white--text font-weight-bold d-block mx-auto mt-5 btn-trans" tile @click="$router.push({name: 'contracts'})">atsfares commissions</v-btn>
                </v-hover>
            </div>
        </v-sheet>

        <!-- about -->
        <section class="mt-10">
            <v-container>
                <div class="text-center">
                    <p class="blueLight--text text-h6">Welcome to</p>
                    <h1 class="mb-3 blueDark--text text-md-h4 font-weight-bold">www.ATSFARES.com</h1>
                    <div class="underline mx-auto"></div>
                    <p class="mt-5 gray--text">ATS is a leading air fare consolidator, deliver the best fares, products, service, and opportunities to travel suppliers, partners, and vendors looking to improve their services and expand their business Working with more than 50+ carriers and being in business since 84' makes us truly your global partner.</p>
                </div>
                <div class="text-center mt-5">
                    <p class="blueLight--text text-h6">Why Travel Agents Choose us ?</p>
                    <v-row justify="center" class="mt-15">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-card outlined class="service-card" min-height="260">
                                <div class="text-center service-card_cont px-3">
                                    <div class="card-icon">
                                        <v-icon size="45" color="white">mdi-airplane</v-icon>
                                    </div>
                                    <p class="blueDark--text font-weight-bold body-1 mt-5">The Most Reliable Airlines</p>
                                    <div class="underline underline--card mx-auto"></div>
                                    <p class="grey--text mt-3">ATS has amassed active contracts with 50+ airlines giving us the ability to offer you a wide range of options for your clients..</p>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-card outlined class="service-card" min-height="260">
                                <div class="text-center service-card_cont px-3">
                                    <div class="card-icon">
                                        <v-icon size="45" color="white">mdi-account-multiple</v-icon>
                                    </div>
                                    <p class="blueDark--text font-weight-bold body-1 mt-5">For our customer service team You come First</p>
                                    <div class="underline underline--card mx-auto"></div>
                                    <p class="grey--text mt-3">We're the fastest growing consolidator in the US and we make sure our customers are growing with us too.</p>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-card outlined class="service-card" min-height="260">
                                <div class="text-center service-card_cont px-3">
                                    <div class="card-icon">
                                        <v-icon size="45" color="white">mdi-magnify</v-icon>
                                    </div>
                                    <p class="blueDark--text font-weight-bold body-1 mt-5">User-Friendly Booking engine</p>
                                    <div class="underline underline--card mx-auto"></div>
                                    <p class="grey--text mt-3">We offer a powerful solutions for small and mid-size travel agencies who are looking for a seamless and reliable technology.</p>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-card outlined class="service-card" min-height="260">
                                <div class="text-center service-card_cont px-3">
                                    <div class="card-icon">
                                        <v-icon size="45" color="white">mdi-calendar-multiple</v-icon>
                                    </div>
                                    <p class="blueDark--text font-weight-bold body-1 mt-5">24 Hours Fast and Reliable Ticketing</p>
                                    <div class="underline underline--card mx-auto"></div>
                                    <p class="grey--text mt-3">We provide 24 hours robotic ticketing system backed by our Quality control team.</p>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>

        <!-- heighlights -->
        <v-sheet color="grey lighten-4 py-15">
            <p class="blueLight--text text-h6 text-center">Highlights on ATS</p>
            <h1 class="mb-3 blueDark--text text-center text-md-h4 font-weight-bold">Products & Services</h1>
            <div class="underline mx-auto"></div>

            <v-row class="px-10 my-10">
                <v-col cols="12" md="6">
                    <v-carousel cycle hide-delimiters show-arrows-on-hover class="rounded-lg">
                        <v-carousel-item
                        v-for="n in 4"
                        :key="n"
                        :src="require(`../assets/slider/${n}.jpeg`)"
                        >
                            <v-sheet width="50%" height="100%" style="background-color: #33333380;" class="pa-5">
                                <v-btn tile color="orange" dark @click="redirect(n)">products</v-btn>
                                <div class="mt-5" v-if="n === 1">
                                    <h2 class="white--text">AIRFARES</h2>
                                    <p class="white--text">
                                        ATS provides High commissions and low net fares on 50+ carriers coupled with rock bottom net rates on over 200,000 hotels worldwide. Our website offers you ease of use with comprehensive platform, we'll help you make money and look like a star.
                                    </p>
                                    <p class="white--text mt-3">
                                        Get full access to our pricing via GDS s, add your markup and sell – you also will have full access to your PNR's. Our booking engine provides you with 24/7, three hundred and sixty-five days a year access to international net and commission fares.
                                    </p>
                                    <!-- <v-btn text color="blueLight" @click="redirect(n)">read more <v-icon right>mdi-arrow-right</v-icon></v-btn> -->
                                </div>
                                <div class="mt-5" v-else-if="n === 2">
                                    <h2 class="white--text">GROUP TRAVEL</h2>
                                    <p class="white--text">
                                        For any group of 10 passengers or more, ATS will be able to assist you in securing best group rates to any destinations domestically or internationally.
                                    </p>
                                    <!-- <v-btn text color="blueLight" @click="redirect(n)">read more <v-icon right>mdi-arrow-right</v-icon></v-btn> -->
                                </div>
                                <div class="mt-5" v-else-if="n === 3">
                                    <h2 class="white--text">HOTELS</h2>
                                    <p class="white--text">
                                        ATS offers discounted fares for hotel rooms in nearly every major city and resort destination worldwide. ATS platform enable You or your client to save significant amounts of money on all type of hotel and room categories.
                                    </p>
                                    <!-- <v-btn text color="blueLight" @click="redirect(n)">read more <v-icon right>mdi-arrow-right</v-icon></v-btn> -->
                                </div>
                                <div class="mt-5" v-else>
                                    <h2 class="white--text">vacation packages</h2>
                                    <p class="white--text">
                                        ATS helps you find the perfect vacation to popular, exotic and exclusive destinations around the world.
                                    </p>
                                    <p class="white--text mt-3">
                                        Our partners get the advantage of established land package rates combined with our competitive airfares which makes for a very well-priced experience for your customers.
                                    </p>
                                    <!-- <v-btn text color="blueLight" @click="redirect(n)">read more <v-icon right>mdi-arrow-right</v-icon></v-btn> -->
                                </div>
                                <v-btn text color="blueLight" @click="redirect(n)">read more <v-icon right>mdi-arrow-right</v-icon></v-btn>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-card rounded flat>
                        <v-img src="../assets/chart.jpeg">
                            <v-btn tile color="orange" class="ma-5" dark @click="$router.push('/about-ats/booking-engine')">services</v-btn>
                        </v-img>
                        <v-card-title>Booking Engine</v-card-title>
                        <v-card-text>
                            ATS platform functions efficiently and cost effectively. It allows all vendor products to come together seamlessly...
                        </v-card-text>
                        <v-card-actions>
                            <v-btn text color="blueLight" @click="$router.push('/about-ats/booking-engine')">read more <v-icon right>mdi-arrow-right</v-icon></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-card rounded flat>
                        <v-img src="../assets/marketing.jpeg">
                            <v-btn tile color="orange" class="ma-5" dark @click="$router.push('/about-ats/services')">services</v-btn>
                        </v-img>
                        <v-card-title>Marketing Assistance</v-card-title>
                        <v-card-text>
                            Providing assistance to integrate ATS booking engine into your own current website and develop a brand If you don’t have it yet...
                        </v-card-text>
                        <v-card-actions>
                            <v-btn text color="blueLight" @click="$router.push('/about-ats/services')">read more <v-icon right>mdi-arrow-right</v-icon></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <v-hover v-slot="{ hover }">
                <v-btn v-if="!user" x-large elevation="5" :color="hover ? 'blueDark' : 'orange'" class="white--text font-weight-bold d-block mx-auto mt-5 btn-trans" tile @click="$router.push('/register')">Partner with us now!</v-btn>
            </v-hover>
        </v-sheet>

        <!-- platform -->
        <section>
            <v-container fluid>
                <v-row class="my-5">
                    <v-col cols="12" md="6" class="px-5">
                        <h1 class="mb-5 primary--text text-md-h4 font-weight-bold">ATS Platform</h1>
                        <p class="grey--text">
                            ATS custom-built online booking engine is technologically advanced to keep up with your demands.
                        </p>
                        <p class="grey--text mt-5">
                            A Dedicated IT team works 24/7 to ensure our fast, streamlined searches are the best fit for your needs.
                        </p>
                        <p class="grey--text mt-5">
                            whether you're large or small agency you can receive one of the leading Global Distribution Systems (GDS) - Sabre, Amadeus World Span Travelport or Travel Port.
                        </p>
                        <p class="grey--text mt-5">
                            The advantages for agents of operating with a GDS include expanded availability and fare searches, bridging our system with yours for booking and pricing, and transferring PNRs for ticketing.
                        </p>
                        <p class="grey--text mt-5">
                            we will help you to improve your sales potential and maximize your profits.
                        </p>
                        <v-hover v-slot="{ hover }">
                            <v-btn v-if="!user" x-large elevation="5" :color="hover ? 'blueDark' : 'orange'" class="white--text font-weight-bold mt-5 btn-trans" tile @click="$router.push('/register')">start with ATS now!</v-btn>
                        </v-hover>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-img src="../assets/chart.jpeg"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <!-- Ticketing -->
        <v-sheet color="blueDark" class="text-center py-15">
            <v-row class="fill-height my-15" justify="center" align="center">
                <v-col cols="12" sm="9" md="6" lg="6" class="white--text">
                    <h1 class="text-body-1 text-md-h5 text-lg-h2 font-weight-bold">24/7 TICKETING</h1>
                    <p class="headline mt-5">Our automated ticketing System is available 24 hours a day, 7 days a week. ATS has you covered! We help our customers all day every day.</p>
                    <v-hover v-slot="{ hover }">
                        <v-btn v-if="!user" x-large elevation="5" :color="hover ? 'blueDark' : 'orange'" class="white--text font-weight-bold mt-5 btn-trans" tile @click="$router.push('/register')">join us today</v-btn>
                    </v-hover>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    model: null
  }),
  watch: {
    model (val) {
      console.log(val)
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    redirect (n) {
      switch (n) {
        case 1:
          this.$router.push('/about-ats/airfares')
          break
        case 2:
          this.$router.push('/about-ats/group-travel')
          break
        case 3:
          this.$router.push('/about-ats/hotels')
          break
        case 4:
          this.$router.push('/about-ats/vacation-packages')
          break
      }
    }
  }
}
</script>

<style>
    .airline-img, .btn-trans {
        transition: all 0.3s ease-in-out;
    }
    .airline-img:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
    }
    .header h1 {
        line-height: 30px;
    }
    .underline {
        width: 150px;
        height: 2px;
        background-color: #FDB71A;
    }
    .underline.underline--card {
        background-color: #2BB5CB;
        width: 80px;
    }
    .service-card {
        position: relative;
    }
    .service-card_cont {
        position: absolute;
        top: -40px;
        left: 0;
    }
    .card-icon {
        background-color: #2BB5CB;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        text-align: center;
        padding-top: 18px;
        margin: auto;
        transition: all 0.3s ease-in-out;
    }
    .card-icon:hover {
        background-color: #202847;
    }
</style>
